import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Theme, Typography } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { StartonButton } from 'components/Core'
import { GoogleSVG } from 'components/SVG'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface IAuthContentSocialConnectProps {
	textLine?: string
}
type StyleProps = Record<string, any>
type StyleClassKey = 'contentAuthButtonContainer' | 'contentAuthButton' | 'contentTextLineContainer' | 'contentTextLine'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	contentAuthButtonContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	contentAuthButton: {
		width: '30%',
		'&[name="facebook"]': {
			background: '#1877F2',
		},
		'&[name="linkedin"]': {
			background: '#0E76A8',
		},
		'&[name="google"]': {
			background: '#EA4335',
		},
	},
	contentTextLineContainer: {
		padding: `${theme.spacing(2)}px 0px`,
		position: 'relative',
		width: '100%',
		textAlign: 'center',
	},
	contentTextLine: {
		color: theme.palette.text.hint,
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENTS
|--------------------------------------------------------------------------
*/
const AuthContentSocialConnect: React.FC<IAuthContentSocialConnectProps> = (props: IAuthContentSocialConnectProps) => {
	const classes: PropClasses = useStyles({} as StyleProps)

	// Handle Facebook
	// ----------------------------------------------------------------------------
	const handleFacebook = () => {
		console.log('Facebook icon')
	}

	// Handle LinkedIn
	// ----------------------------------------------------------------------------
	const handleLinkedIn = () => {
		console.log('LinkedIn icon')
	}

	// Handle Google
	// ----------------------------------------------------------------------------
	const handleGoogle = () => {
		console.log('Google icon')
	}

	// Render
	// ----------------------------------------------------------------------------
	return (
		<React.Fragment>
			<Box className={classes.contentTextLineContainer}>
				<Typography className={classes.contentTextLine}>{props.textLine}</Typography>
			</Box>
			<Box className={classes.contentAuthButtonContainer}>
				<StartonButton name="facebook" className={classes.contentAuthButton} onClick={handleFacebook}>
					<FacebookIcon />
				</StartonButton>
				<StartonButton name="linkedin" className={classes.contentAuthButton} onClick={handleLinkedIn}>
					<LinkedInIcon />
				</StartonButton>
				<StartonButton name="google" className={classes.contentAuthButton} onClick={handleGoogle}>
					<GoogleSVG fill="#ffffff" />
				</StartonButton>
			</Box>
		</React.Fragment>
	)
}

/*
|--------------------------------------------------------------------------
| DEFAULT PROPS
|--------------------------------------------------------------------------
*/
AuthContentSocialConnect.defaultProps = {
	textLine: 'or connect with',
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { AuthContentSocialConnect }
