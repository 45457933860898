import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Theme, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { StartonLink, StartonWhiteLabelLogo } from 'components/Core'
import platform from '../../../.tmp/platform'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
interface IAuthContentSideFooterProps {
	hintText: string
	linkText: string
	linkHref: string
}
export interface IAuthContentSideProps {
	title: string
	content: React.ReactNode
	footerProps: IAuthContentSideFooterProps
	errorMessage?: string
}
type StyleProps = Record<string, any>
type StyleClassKey = 'contentSide' | 'contentLogo' | 'contentTitle' | 'rightTitle' | 'errorMessage'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	contentSide: {
		width: '95%',
		minHeight: '87vh',
		borderRadius: 30,
		margin: '1.5vh auto 1.5vh',
		backgroundColor: theme.palette.background.default,
		zIndex: 2,
		boxShadow: '-2px 0px 18px 0px #00000038',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
		[theme.breakpoints.up('md')]: {
			width: 550,
			minHeight: '100vh',
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			borderTopLeftRadius: 30,
			borderBottomLeftRadius: 30,
			margin: 'initial',
			padding: `${theme.spacing(5)}px ${theme.spacing(10)}px`,
		},
	},
	contentLogo: {
		height: 110,
		marginBottom: theme.spacing(2),
		'& img': {
			height: 110,
		},
	},
	contentTitle: {
		fontWeight: 700,
		fontSize: theme.typography.pxToRem(18),
		textAlign: 'center',
		marginBottom: theme.spacing(4),
	},
	rightTitle: {
		fontWeight: 400,
		fontSize: theme.typography.pxToRem(18),
		textAlign: 'center',
		marginBottom: theme.spacing(4),
		opacity: 0.75,
		color: theme.palette.secondary.main,
	},
	errorMessage: {
		marginBottom: theme.spacing(4),
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENTS
|--------------------------------------------------------------------------
*/
const AuthContentSide: React.FC<IAuthContentSideProps> = (props: IAuthContentSideProps) => {
	const classes: PropClasses = useStyles({} as StyleProps)

	return (
		<Box className={classes.contentSide}>
			{/* Company contentLogo */}
			<Box className="flex flex-col items-center">
				<StartonLink href="/" className={classes.contentLogo}>
					<StartonWhiteLabelLogo />
				</StartonLink>
				<Typography className={classes.rightTitle}>{platform?.frontSettings?.auth?.rightTitle}</Typography>
			</Box>
			{/* Content */}
			<Box className="w-full">
				<Typography className={classes.contentTitle}>{props.title}</Typography>
				{Boolean(props.errorMessage) && (
					<Alert variant="outlined" severity="error" className={classes.errorMessage}>
						{props.errorMessage}
					</Alert>
				)}
				{props.content}
			</Box>
			{/* Footer */}
			<Box className="flex flex-col items-center mt-6">
				<Typography>{props.footerProps.hintText}</Typography>
				<StartonLink href={props.footerProps.linkHref}>{props.footerProps.linkText}</StartonLink>
			</Box>
		</Box>
	)
}

export { AuthContentSide }
