import * as React from 'react'
import { NextPage } from 'next'
import { makeStyles } from '@material-ui/styles'
import { Box, IconButton, InputAdornment, Theme } from '@material-ui/core'
import { NextSeo } from 'next-seo'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { TextField } from 'formik-material-ui'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { StartonButton, StartonLink } from 'components/Core'
import { AuthLayout } from 'containers/Authentifications'
import { COOKIE_TOKEN_KEY, setUser } from 'stores/user'
import starton from 'data/axios'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
interface IForm {
	email: string
	password: string
}
type StyleProps = Record<string, any>
type StyleClassKey = 'textFieldInput' | 'forgotPasswordContainer' | 'forgotPasswordLink'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	textFieldInput: {
		marginBottom: theme.spacing(2),
	},
	forgotPasswordContainer: {
		position: 'relative',
		marginTop: 10,
	},
	forgotPasswordLink: {
		position: 'absolute',
		top: -17,
		right: 0,
		fontSize: theme.typography.pxToRem(12),
	},
}))

/*
|--------------------------------------------------------------------------
| PAGE
|--------------------------------------------------------------------------
*/
const LoginPage: NextPage = () => {
	const dispatch = useDispatch()
	const router = useRouter()
	const classes: PropClasses = useStyles({} as StyleProps)
	const [showPassword, setShowPassword] = React.useState<boolean>(false)
	const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined)
	const { t } = useTranslation()

	// Handle submit
	// ----------------------------------------------------------------------------
	const handleSubmit = async (values: IForm, formikHelpers: FormikHelpers<IForm>) => {
		// Set submitting
		formikHelpers.setSubmitting(true)

		// Reset error message
		setErrorMessage(undefined)

		// Try send login
		try {
			const user = await starton.post('/auth/login', {
				email: values.email,
				password: values.password,
			})
			if (user.data) {
				Cookies.set(COOKIE_TOKEN_KEY, user.data.accessToken, { expires: 60 })
				dispatch(setUser(user.data.user))
				starton.defaults.headers.common['Authorization'] = `Bearer ${user.data.accessToken}`

				// Redirect user to homepage
				const pathUrl = router.query.redirect_url
				pathUrl !== undefined && !(Object.keys(pathUrl).length === 0 && pathUrl.constructor === Object)
					? router.replace({
							pathname: `/${pathUrl}`,
					  })
					: router.replace({
							pathname: '/',
					  })
			}
		} catch (e) {
			if (e.response.data.errorCode === 'WrongPasswordException') {
				setErrorMessage(t('auth.your_password_is_incorrect'))
			} else if (e.response.data.errorCode === 'UserNotFoundException') {
				setErrorMessage(t('auth.no_account_email'))
			} else if (e.response.data.errorCode === 'UserWrongStatusException') {
				router.replace({
					pathname: '/waiting-confirmation',
				})
			} else {
				setErrorMessage(t('tools.error_occurred'))
			}
			formikHelpers.setSubmitting(false)
		}
	}

	const registerLink = () => {
		const pathUrl = router.query.redirect_url
		return pathUrl !== undefined && !(Object.keys(pathUrl).length === 0 && pathUrl.constructor === Object)
			? `/register?redirect_url=${pathUrl}`
			: '/register'
	}

	// Handle password show password
	// ----------------------------------------------------------------------------
	const handleClickShowPassword = () => {
		setShowPassword((prevState) => !prevState)
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const schemaValidations = Yup.object().shape({
		email: Yup.string().email(t('tools.valid_mail')).required(t('tools.field_required')),
		password: Yup.string().required(t('tools.field_required')),
	})
	// Render
	// ----------------------------------------------------------------------------
	return (
		<React.Fragment>
			<NextSeo title={t('auth.login_page')} description={t('auth.login_page')} />
			<AuthLayout
				mediaSide={{
					title: '',
					subTitle: '',
				}}
				contentSide={{
					title: t('auth.sign_in_to_your_account'),
					footerProps: {
						hintText: t('auth.dont_have_account'),
						linkText: t('auth.create_your_account_now'),
						linkHref: registerLink(),
					},
					errorMessage,
				}}
			>
				<React.Fragment>
					<Formik
						initialValues={{
							email: '',
							password: '',
						}}
						onSubmit={handleSubmit}
						validationSchema={schemaValidations}
					>
						{(formikProps) => (
							<Form>
								<Field
									component={TextField}
									className={classes.textFieldInput}
									type="email"
									id="email"
									name="email"
									label={t('auth.your_email')}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									fullWidth
								/>
								<Box className={classes.forgotPasswordContainer}>
									<Field
										component={TextField}
										className={classes.textFieldInput}
										type={showPassword ? 'text' : 'password'}
										id="password"
										name="password"
										label={t('auth.your_password')}
										variant="outlined"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
										}}
										InputLabelProps={{
											shrink: true,
										}}
										fullWidth
									/>
									<StartonLink href="/forgot-password" className={classes.forgotPasswordLink}>
										{t('auth.forgot_password')}
									</StartonLink>
								</Box>
								<StartonButton
									fullWidth
									type="submit"
									disabled={!formikProps.isValid}
									loading={formikProps.isSubmitting}
								>
									{t('auth.sign_in')}
								</StartonButton>
							</Form>
						)}
					</Formik>
					{/*<AuthContentSocialConnect />*/}
				</React.Fragment>
			</AuthLayout>
		</React.Fragment>
	)
}

export default LoginPage
