import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import Particles from 'react-particles-js'
import platform from '../../../.tmp/platform'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface IAuthMediaSideProps {
	title: string
	subTitle: string
}
type StyleProps = Record<string, any>
type StyleClassKey = 'mediaSide' | 'mediaSideTitle' | 'mediaLogo' | 'mediaDiv'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	mediaSide: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		background: `transparent linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%) 0% 0% no-repeat padding-box`,
		color: 'white',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		padding: theme.spacing(4),
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			textAlign: 'left',
		},
	},
	mediaLogo: {},
	mediaDiv: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		paddingRight: '40em',
		paddingLft: '0em !important',
	},
	mediaSideTitle: {
		fontWeight: 700,
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENTS
|--------------------------------------------------------------------------
*/
const AuthMediaSide: React.FC<IAuthMediaSideProps> = (props: IAuthMediaSideProps) => {
	const classes: PropClasses = useStyles({} as StyleProps)
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	// Render
	// ----------------------------------------------------------------------------
	return (
		<Box className={classes.mediaSide}>
			{platform?.frontSettings?.logo?.full ? (
				<div className={classes.mediaDiv}>
					<img src={platform?.frontSettings?.logo?.full} alt="" className={classes.mediaLogo} />
				</div>
			) : null}
			<Particles
				params={{
					particles: {
						number: {
							value: 160,
							density: {
								enable: false,
							},
						},
						size: {
							value: 3,
							random: true,
							anim: {
								speed: 4,
								size_min: 0.3,
							},
						},
						line_linked: {
							enable: false,
						},
						move: {
							random: true,
							speed: 1,
							direction: 'top',
							out_mode: 'out',
						},
					},
					interactivity: {
						events: {
							onhover: {
								enable: true,
								mode: 'bubble',
							},
							onclick: {
								enable: true,
								mode: 'repulse',
							},
						},
						modes: {
							bubble: {
								distance: 250,
								duration: 2,
								size: 0,
								opacity: 0,
							},
							repulse: {
								distance: 400,
								duration: 4,
							},
						},
					},
				}}
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					width: '100vw',
					height: '100vh',
				}}
			/>
			{!isMobile && (
				<React.Fragment>
					<Typography className={classes.mediaSideTitle}>
						{platform?.frontSettings?.auth?.title || props.title}
					</Typography>
					<Typography>{platform?.frontSettings?.auth?.subTitle || props.subTitle}</Typography>
				</React.Fragment>
			)}
		</Box>
	)
}

export { AuthMediaSide }
