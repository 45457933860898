import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Theme } from '@material-ui/core'
import { AuthMediaSide, IAuthMediaSideProps } from 'containers/Authentifications/AuthMediaSide'
import { AuthContentSide, IAuthContentSideProps } from 'containers/Authentifications/AuthContentSide'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
interface IAuthLayoutProps {
	children: React.ReactNode
	mediaSide: IAuthMediaSideProps
	contentSide: Omit<IAuthContentSideProps, 'content'>
}
type StyleProps = Record<string, any>
type StyleClassKey = 'container'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>({
	container: {
		position: 'relative',
		width: '100vw',
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
})

/*
|--------------------------------------------------------------------------
| COMPONENTS
|--------------------------------------------------------------------------
*/
const AuthLayout: React.FC<IAuthLayoutProps> = (props: IAuthLayoutProps) => {
	const classes: PropClasses = useStyles({} as StyleProps)

	// Render
	// ----------------------------------------------------------------------------
	return (
		<Box className={classes.container}>
			<AuthMediaSide {...props.mediaSide} />
			<AuthContentSide {...props.contentSide} content={props.children} />
		</Box>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { AuthLayout }
